/* body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  text-align: center;
  color: #293c4b;
}

h1 {
  font-size: 30px;
}

img {
  margin: 20px 0;
  max-width: 200px;
} */

body {
  background-color: black;
}

body > div {
  height: 100%;
}

.solved::after {
  content: '';
  position: absolute;
  height: 0;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 30px;
  border-right-color: #fff;
  border-top-color: #fff;
}

.pre * {
  white-space: pre !important;
}

input.number:invalid {
  background-color: rgb(26, 0, 0);
}

.dotted {
  background: radial-gradient(ellipse at center, #444 25%, #444 25%, transparent 25%);
  background-size: 10px 10px;
  background-repeat: repeat-x;
  background-position: bottom;
}
